<template>
  <div class="sign-up-form">
    <div class="form-header">
      <a href="https://community.spiceworks.com">
        <span class="spiceworks-logo-form"></span>
      </a>
    </div>

    <div>
      <h1 class="form-title">Create your free account now</h1>
      <div class="form-text">
        Trusted by technology professionals across the globe since 2006.
      </div>
    </div>

    <div v-if="genericError" class="generic-error-container">
      <div class="generic-error_icon" />
      <div class="generic-erro-body">
        <div class="generic-error-text" v-html="genericError" />
      </div>
    </div>

    <div v-if="genericInfo" class="generic-info-container">
      <div class="generic-info_icon" />
      <div class="generic-info-body">
        <div class="generic-info-text" v-html="genericInfo" />
      </div>
    </div>

    <form class="form-grid" action="/join" method="post" @submit.prevent="validateRecaptcha" id="signup-form">
      <div :class="['input-wrapper', invalidEmail ? 'is-error' : '']">
        <input
          v-model="email"
          @blur="validateEmailAddress"
          id="email-field"
          class="text-input"
          name="user[email]"
          type="email" placeholder=" "
          :readonly="referrerSource == 'chd_addon_user_registration' && userEnteredEmail !== '' ? true : false"
        />
        <label for="email-field">Email Address</label>
        <div class="error-msg" v-if="invalidEmail" v-html="emailErrorMsg" />
      </div>

      <PasswordFields
        passwordFieldName="user[password]"
        confirmPasswordFieldName="user[password_confirmation]"
        ref="passwordFieldsRef"/>
      <PasswordRequirementsInfo />

      <input type="hidden" name="authenticity_token" :value="csrfToken" />
      <input type="hidden" name="user[referer]" :value="referer" />
      <input type="hidden" name="user[referrer_source]" :value="referrerSource" />
      <input type="hidden" name="user[referrer_reference]" :value="referrerReference" />
      <input type="hidden" name="user[community_first_referrer_full_url]" :value="communityFirstReferrerFullUrl" />
      <input type="hidden" name="user[chd_invite_token]" :value="chdInviteToken" />
      <input type="hidden" name="user[query_string]" :value="queryString" v-if="queryString" />

      <div class="form-row">
        <input v-model="acceptTerms" class="checkbox-input" name="user[accept_terms]" type="checkbox" @change="disableSubmit" />
        <label class="checkbox-label" v-html="consentLanguage"></label>
      </div>

      <div id="recaptcha"
        class="g-recaptcha"
        :data-sitekey="recaptchaKey"
        data-callback="generatedRecaptcha"
        data-size="invisible"
      />

      <button class="submit-button" type="submit" :disabled="isSubmitDisable">Join Spiceworks</button>
    </form>

    <div class="form-text">
      Already a member? <a class="form-link" :href=appendReferrerParams(loginUrl)>Login</a>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watchEffect } from 'vue';
import PasswordFields from '@/password-fields/password-fields.vue';
import PasswordRequirementsInfo from '@/password-fields/password-requirements.vue';

const {
    referer,
    referrerSource,
    referrerReference,
    communityFirstReferrerFullUrl,
    consentLanguage,
    recaptchaKey,
    genericErrorType,
    genericError,
    genericInfo,
    userEnteredEmail,
    chdInviteToken,
    discoursePublicUrl
  } = defineProps([
    'referer',
    'referrerSource',
    'referrerReference',
    'communityFirstReferrerFullUrl',
    'consentLanguage',
    'recaptchaKey',
    'genericErrorType',
    'genericError',
    'genericInfo',
    'userEnteredEmail',
    'chdInviteToken',
    'discoursePublicUrl'
  ]);

const email = ref(userEnteredEmail || '');
const acceptTerms = ref(false);
const loginUrl = '/sign_in'
const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
const invalidEmail = ref(false);
const emailErrorMsg = ref('');
const isSubmitDisable = ref(true);
const isRecaptchaReady = ref(false);
const queryString = window.location.search;
const passwordFieldsRef = ref(null);

const appendReferrerParams = (path) => {
  const redirectUrl = new URL(`${window.location.origin}${path}`);
  const currentParams = new URLSearchParams(window.location.search)

  const getFullUrl = () => {
    redirectUrl.search = currentParams.toString();
    return redirectUrl.toString();
  }

  const getReferrers = (referer) => {
    let apps = ["connectivity-dashboard", "contracts", "device-inventory"];

    if(apps.some(app => referer.includes(app))) {

      // set referrer_source param
      const matchedApp = apps.find(app => referer.includes(app));
      if (matchedApp) {
        currentParams.append('referrer_source', matchedApp.replace("-", '_'));
      }

      // set referrer_reference param
      if (referer.includes('invite_token')) {
        currentParams.append('referrer_reference', 'invite');
      } else if (referer.includes('registration')) {
        currentParams.append('referrer_reference', 'registration');
      }
    }
  }

  const hasRefererParam = currentParams.has('referer');
  const noRefParams = !currentParams.has('referrer_source') && !currentParams.has('referrer_reference');

  if(hasRefererParam) {
    currentParams.append('success', currentParams.get('referer'));

    if(noRefParams) {
      getReferrers(currentParams.get('referer'));
    }
  }

  const hasRefParams = currentParams.has('referrer_source') && currentParams.has('referrer_reference');

  if(hasRefParams) {
    if (currentParams.get('referrer_reference') == 'daily_challenge' && !currentParams.has('success')) {
      const success_url = new URL(`${discoursePublicUrl}/session/sso`);

      currentParams.append('success', success_url.toString());
    }
    return getFullUrl();
  } else {
    currentParams.has('referrer_source') ? null : currentParams.set('referrer_source', 'join');
    currentParams.has('referrer_reference') ? null : currentParams.set('referrer_reference', 'loginlink');
  }

  return getFullUrl();
}

const validateEmailAddress = () => {
  if (email.value.length > 254) {
    invalidEmail.value = true
    emailErrorMsg.value = 'The email address must be < 255 characters.';

  } else if (/^(?=.{6,254})(?!.*\.\.+.*)(?!\.)([A-Za-z0-9!#$%&'*+/=?^_`{|}~.-]){1,64}(?!\.)@([-A-Za-z0-9])+\.([-A-Za-z0-9]+\.)*([-A-Za-z]){2,63}$/.test(email.value)) {
    invalidEmail.value = false;
  } else {
    invalidEmail.value = (email.value.length > 0);
    switch(true) {
      case (email.value.includes('@.')):
        emailErrorMsg.value = 'The email address you have entered has a period immediately following the @ sign.';
        break;
      case (email.value.includes('.@')):
        emailErrorMsg.value = 'The email address you have entered has a period immediately before the @ sign.';
        break;
      case (email.value.startsWith('.')):
        emailErrorMsg.value = 'The email address you have entered start with a period.';
        break;
      case ((email.value.match(/@/g) || []).length < 1):
        emailErrorMsg.value = 'The email address you have entered is missing an \'@\' sign.';
        break;
      case (email.value.endsWith('.')):
        emailErrorMsg.value = 'The email address you have entered ends with a period.';
        break;
      case ((email.value.match(/@/g) || []).length > 1):
        emailErrorMsg.value = 'The email address you have entered has too many \'@\' signs.';
        break;
      case ((email.value.split('@')[1]).length === 0):
        emailErrorMsg.value = 'The email address you have entered is missing the domain.';
        break;
      case ((email.value.split('@')[0]).length === 0):
        emailErrorMsg.value = 'The email address you have entered is missing your username/handle.';
        break;
      case ((email.value.split('@')[1]).includes('..')):
        emailErrorMsg.value = 'The email address you have entered has a double period \'..\' after the @ sign.';
        break;
      default:
        emailErrorMsg.value = 'The email address you have entered is not valid.';
    }
  }
  disableSubmit();
}

const disableSubmit = () => {
  const validPasswordFields = passwordFieldsRef.value?.validatePasswordFields();
  isSubmitDisable.value = !(email.value !== '' && !invalidEmail.value && validPasswordFields && acceptTerms.value && genericErrorType !== 'chd_token_validation_error');
}

watchEffect(() => {
  const passwordFieldsBlurs = passwordFieldsRef.value?.blurs;
  const passwordFieldsKeyups = passwordFieldsRef.value?.keyups;
  setTimeout(disableSubmit, 500);
});

const validateRecaptcha = (event) => {
  isSubmitDisable.value = true;

  if (isRecaptchaReady.value) {
    document.getElementById("signup-form").submit();
  } else {
    grecaptcha.execute();
  }
}

const generatedRecaptcha = (token) => {
  isRecaptchaReady.value = true;
  // Re-call the submit function to actually submit the form.
  validateRecaptcha();
}

window.generatedRecaptcha = generatedRecaptcha;
</script>

<style lang="scss" scoped>
@import '@join/join_flow_styles/swzd-variables.scss';

.sign-up-form {
  width: 70%;
  margin: 0 auto;
  display: grid;
  gap: 24px;
}

@media (max-width: 550px) {
  .sign-up-form {
    width: 100%;
  }
}

.form-title {
  font-size: 24px;
  font-weight: normal;
  margin: 0;
}

.form-text {
  line-height: 22px;
}

.form-grid {
  display: grid;
  gap: 16px;
}

.g-recaptcha {
  z-index: 100;
}

.input-wrapper {
  position: relative;
}

.text-input {
  width: 100%;
  padding: 10px 30px 10px 10px;
  border: 1px solid $border-default;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;

  &[readonly] {
    border: 1px solid $button-default-selected;
    color: $text-secondary;
    background-color: $table-row-alt;
    pointer-events: none;
  }
}

.text-input + label {
  position: absolute;
  top: 11px;
  left: 10px;
  transition: top 0.3s, font-size 0.3s, color 0.3s, transform 0.3s ease-in-out;
  pointer-events: none;
  color: $text-secondary;
  transform-origin: left top;
  padding: 0 4px;
  background-color: $white;
  border-radius: 3px;
}

.text-input:focus {
  border-color: $border-focused;
}

.text-input:focus + label,
.text-input:not(:placeholder-shown) + label {
  left: 10px;
  transform: scale(0.75) translateY(-24px);
  padding: 0 4px;
  color: $text-default;
  background-color: $white;
}

input.text-input[readonly] + label {
  color: $text-secondary;
}

.submit-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background-color: $button-primary;
  color: $white;
  padding: 7px 12px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  width: 100%;

  &:hover:enabled {
    background-color: $button-primary-hover;
  }

  &[disabled] {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.form-row {
  display: flex;
  align-items: flex-start;
}

.checkbox-input {
  margin-right: 8px;
  margin-top: 4px;
}

.checkbox-label {
  line-height: 22px;
  flex: 1;
}

.checkbox-label a {
  color: $text-link;
}

.is-error {
  input, input:focus {
    border-color: $text-error;
  }

  label {
    color: $text-error !important;
  }

  .error-msg {
    color: $text-error;
    margin-top: 3px;
    font-size: 12px;
    line-height: 16px;
    padding: 0 12px;
  }
}
</style>
